import React, { forwardRef, useEffect, useRef, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { shape, string } from 'prop-types';
import classNames from 'classnames';
import style from './style.less';
import Button from '../button';

const CpmSubBanner = forwardRef((props, ref) => {
  useStyles(style);
  const { bannerContent, className, bannerBackGroundColor, bannerTitleColor, testId, onClick, ...rest } = props;

  const bannerEl = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (bannerEl.current) {
      setScrollTop(bannerEl.current.offsetTop);
    }
  }, [bannerEl]);

  useEffect(() => {
    const onScroll = () => {
      const currentPosition = window.pageYOffset;
      if (currentPosition > scrollTop) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);
  return (
    <>
      <div
        className={classNames(style.Container, isSticky ? style.Sticky : '', className)}
        style={{ backgroundColor: bannerBackGroundColor }}
        ref={bannerEl}
        data-testid={testId || ''}
        {...rest}
      >
        <div className={classNames(style.CpmSubBannerContainer)} ref={ref}>
          <div className={classNames(style.CpmSubBannerTitle)}>
            <h4 style={{ color: bannerTitleColor }}>{bannerContent.bannerTitle}</h4>
          </div>
          <Button
            type="primaryLink"
            href={bannerContent.buttonHref}
            className={classNames(style.CpmCtaButton)}
            onClick={onClick}
            matomoEventType={bannerContent.buttonMatomoEventType}
          >
            {bannerContent.buttonTitle}
          </Button>
        </div>
      </div>
      {isSticky && <div className={style.Container} />}
    </>
  );
});

CpmSubBanner.propTypes = {
  bannerContent: shape({
    buttonTitle: string.isRequired,
    buttonHref: string,
    bannerTitle: string.isRequired,
    buttonMatomoEventType: string,
  }).isRequired,
  bannerBackGroundColor: string,
  bannerTitleColor: string,
  className: string,
};

CpmSubBanner.defaultProps = {
  bannerBackGroundColor: '#000000',
  bannerTitleColor: '#ffffff',
  className: '',
};

export default CpmSubBanner;
