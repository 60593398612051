import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { arrayOf, shape, string } from 'prop-types';
import classNames from 'classnames';
import s from './style.less';
import Button from '../button';

const Banner = ({ titles, descriptions, link, className }) => {
  useStyles(s);

  return (
    <div className={classNames(s.Container, className)}>
      <div className={s.BannerTextArea}>
        <div className={s.BannerTitle}>
          {titles.map((text) => (
            <h1 key={text}>{text}</h1>
          ))}
        </div>
        <div className={s.BannerDescription}>
          {descriptions.map((text) => (
            <p key={text}>{text}</p>
          ))}
        </div>
        <div>
          {link && (
            <Button type="secondaryLink" href={link.url} matomoEventType={link.matomoEventType}>
              {link.text}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  titles: arrayOf(string),
  descriptions: arrayOf(string),
  link: shape({
    text: string.isRequired,
    url: string.isRequired,
  }),
  className: string,
};

Banner.defaultProps = {
  titles: [],
  descriptions: [],
  link: null,
  className: '',
};

export default Banner;
