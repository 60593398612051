import React, { useRef } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { func, oneOf, string } from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import style from './style.less';

const Button = (props) => {
  useStyles(style);
  const buttonRef = useRef();
  const { type, size, className, children, onClick, disabled, testId, matomoEventType, ...rest } = props;
  const isLinkButton = type === 'secondaryLink' || type === 'primaryLink';

  const handleClick = (e) => {
    if (!isLinkButton) {
      e.preventDefault();
    }
    if (!disabled) {
      onClick(e);
    }
    if (buttonRef.current && _.isFunction(buttonRef.current.blur)) {
      buttonRef.current.blur();
    }
  };

  if (isLinkButton && rest.href !== undefined) {
    return (
      <a
        className={classNames(
          style.Root,
          style[type],
          disabled ? style[`${type}-disabled`] : '',
          size === 'small' ? style.Small : '',
          className
        )}
        aria-label={rest['aria-label'] ? rest['aria-label'] : children}
        onClick={handleClick}
        role="button"
        rel="noreferrer"
        tabIndex={0}
        data-testid={testId}
        {...rest}
        data-matomo={matomoEventType}
      >
        <span>{children}</span>
        {type === 'secondaryLink' && <i className="ri-arrow-right-s-line" />}
      </a>
    );
  }

  return (
    <button
      ref={buttonRef}
      className={classNames(
        style.Root,
        style[type],
        disabled ? style[`${type}-disabled`] : '',
        size === 'small' ? style.Small : '',
        className
      )}
      data-testid={testId}
      type="button"
      {...rest}
      onClick={handleClick}
      data-matomo={matomoEventType}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  type: oneOf(['primaryLink', 'primary', 'secondaryLink']),
  className: string,
  testId: string,
  size: oneOf(['small', 'default']),
  matomoEventType: string,
  onClick: func,
};

Button.defaultProps = {
  type: 'primary',
  className: '',
  testId: '',
  size: 'default',
  matomoEventType: '',
  onClick: () => {},
};

export default Button;
